import { buildUrl, get, post } from '@lib/api';
import Result from "@lib/result";
import { AvailableLoadSummaryResponse } from 'shared/models/loads/available-load-summary-response.model';
import { ReloadsResponse } from 'shared/models/loads/reloads-response.model';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { store } from 'store';

const baseUrl = apiConfig.searchAPI;
const sourceSystemHeaderKey = 'Web-NavCarrier';
const allowableEquipmentTypesForReloads = Object.freeze(["V", "R"]);

interface DataWithRadius {
  originRadiusMiles: number;
  destinationRadiusMiles: number;
}

const cleanupCriteria = criteria => {
  for (const [key, value] of Object.entries(criteria)) {

    if (value === '') {
      delete criteria[key];
    }

    if (key === 'mode' || key === 'specializedEquipmentCode') {
      if (value === EquipmentType.All) {
        delete criteria[key];
      }
    }
  }
  return criteria;
}

const normalizeWeightAndDistance = (criteria: AvailableLoadSearchCriteriaJSON): AvailableLoadSearchCriteriaJSON => {
  return {
    ...criteria,
    weightMin: criteria.weightMin != null ? Math.round(criteria.weightMin) : null,
    weightMax: criteria.weightMax != null ? Math.round(criteria.weightMax) : null,
    milesMin: criteria.milesMin != null ? Math.round(criteria.milesMin) : null,
    milesMax: criteria.milesMax != null ? Math.round(criteria.milesMax) : null,
  };
};

const useActivityDates = (criteria: AvailableLoadSearchCriteriaJSON): AvailableLoadSearchCriteriaJSON => {
  if (!criteria.pickupStart || !criteria.pickupEnd) {
    return criteria;
  }

  const newCriteria = {
    ...criteria,
    activityStart: criteria.pickupStart,
    activityEnd: criteria.pickupEnd,
  };
  delete newCriteria.pickupStart;
  delete newCriteria.pickupEnd;
  return newCriteria;
};

const normalizeRadius = <T extends DataWithRadius>(data: T): T => {
  // API cannot accept floats for origin or destination radius,
  // but we need to internally maintain floats (elsewhere) for better conversion
  return {
    ...(data as any),
    originRadiusMiles: data.originRadiusMiles != null ? Math.round(data.originRadiusMiles) : null,
    destinationRadiusMiles: data.destinationRadiusMiles != null ? Math.round(data.destinationRadiusMiles) : null,
  };
};

const validCriteria = (criteria: AvailableLoadSearchCriteriaJSON): boolean => {
  if (!criteria) {
    return false;
  }
  const hasOrigin = !!criteria.originLatitude && !!criteria.originLongitude;
  const hasDestination = !!criteria.destinationLatitude && !!criteria.destinationLongitude;
  return hasOrigin || hasDestination;
}

const getPreferredCurrencyCode = () => {
  return store.getState().auth?.user?.properties?.preferredCurrencyCode;
}

export async function getRecommendedShipmentsAsync(correlationId: string): Promise<result<AvailableLoadSummaryResponse>> {
  const customHeaders: any = {
    'X-CorrelationId': correlationId,
    'X-SourceSystem': sourceSystemHeaderKey,
  };

  let url = buildUrl(baseUrl, "shipments/recommended");
  const preferredCurrencyCode = getPreferredCurrencyCode();
  if (preferredCurrencyCode) {
    const params = new URLSearchParams();
    params.append('preferredCurrencyCode', preferredCurrencyCode);
    url = `${url}?${params.toString()}`;
  }

  const result = await get<AvailableShipmentResponseJSON>(
    url,
    customHeaders);

  return result.success
    ? Result.Success(new AvailableLoadSummaryResponse(result.value, true))
    : Result.Fail(result.error);
}

export async function searchAvailableShipmentsAsync(correlationId: string, criteria: AvailableLoadSearchCriteriaJSON): Promise<result<AvailableLoadSummaryResponse>> {
  if (!validCriteria(criteria)) {
    return Result.Fail("Invalid search criteria. Must have origin or destination coordinates.");
  }

  criteria = normalizeWeightAndDistance(normalizeRadius(cleanupCriteria(criteria)));
  criteria = useActivityDates(criteria);

  const additionalCriteria = {
    preferredCurrencyCode: getPreferredCurrencyCode(),
  };

  const customHeaders: any = {
    'X-CorrelationId': correlationId,
    'X-SourceSystem': sourceSystemHeaderKey,
  };

  const result = await post<AvailableShipmentResponseJSON>(
    buildUrl(baseUrl, "shipments/search"),
    customHeaders,
    { ...criteria, ...additionalCriteria });

  return result.success
    ? Result.Success(new AvailableLoadSummaryResponse(result.value, false))
    : Result.Fail(result.error);
}

export async function searchReloadShipmentsAsync(criteria: ReloadShipmentsSearchCriteriaJSON): Promise<result<ReloadsResponse>> {
  if (!criteria || !criteria.primalLoadDetails) {
    return Result.Fail("Invalid search criteria.");
  }

  const equipmentType = criteria.primalLoadDetails?.equipmentType.toUpperCase();
  if (equipmentType && !allowableEquipmentTypesForReloads.includes(equipmentType)) {
    return Result.Fail("Invalid equipment type.");
  }

  const additionalCriteria = {
    preferredCurrencyCode: getPreferredCurrencyCode(),
  };

  const customHeaders: any = {
    'X-SourceSystem': sourceSystemHeaderKey,
  };

  const result = await post<ReloadsSearchResponseJSON>(
    buildUrl(baseUrl, "shipments/reloads"),
    customHeaders,
    { ...criteria, ...additionalCriteria });

  return result.success
    ? Result.Success(new ReloadsResponse(result.value))
    : Result.Fail(result.error);
}

export default {
  getRecommendedShipmentsAsync,
  searchAvailableShipmentsAsync,
  searchReloadShipmentsAsync
};