export default class Result<Type> implements result<Type> {
  success: boolean;
  value: Type;
  error: resultError;

  private constructor(success: boolean, value: Type, error: resultError) {
    this.success = success;
    this.value = value;
    this.error = error;
  }

  static Success<Type>(value: Type) {
    return new Result(true, value, null);
  }

  static Fail(error: unknown) {
    return new Result(false, null, Result.#getError(error));
  }

  static #getError(error: unknown): resultError {
    if (typeof error === 'string') {
      return { code: 0, message: error };
    }

    if (error instanceof Error) {
      return { code: 0, message: error.message };
    }

    if (typeof error === 'object' &&
      error !== null &&
      error !== undefined) {

      const message = error.hasOwnProperty('message') && typeof error["message"] === 'string'
        ? error["message"] as string
        : 'An error occurred';

      const code = error.hasOwnProperty('code') && typeof error["code"] === 'number'
        ? error["code"] as number
        : 0;

      return { code, message };
    }

    return { code: 0, message: 'An error occurred' };
  }
}